import * as Yup from 'yup';

export const validationSchema = Yup.object({
  // Personal
  name: Yup.string().required('Name is required'),
  surname: Yup.string().required('Surname is required'),
  dob: Yup.date().required('Date of Birth is required'),
  retirementAge: Yup.number().positive().integer().required('Retirement Age is required'),
  occupation: Yup.string().required('Occupation is required'),
  incomePerYear: Yup.number().positive().required('Income Per Year is required'),
  
  // Expenditure
  monthlyEssentialSpending: Yup.number().positive().required('Annual Essential Spending is required'),
  monthlyDiscretionarySpending: Yup.number().positive().required('Annual Discretionary Spending is required'),
  
  // Personal Pension
  currentPensionValue: Yup.number().min(0).required('Current Pension Value is required'),
  pensionType: Yup.string().oneOf(['Personal Pension', 'SIPP', 'Group Pension', 'DB']).required('Pension Type is required'),
  annualContributions: Yup.object({
    employee: Yup.object({
      type: Yup.string().oneOf(['percentage', 'fixed']).required('Employee contribution type is required'),
      value: Yup.number().min(0).required('Employee contribution value is required'),
    }),
    employer: Yup.object({
      type: Yup.string().oneOf(['percentage', 'fixed']).required('Employer contribution type is required'),
      value: Yup.number().min(0).required('Employer contribution value is required'),
    }),
  }),
  annualGrowthRate: Yup.number().min(0).max(100),
  annualFees: Yup.number().min(0).max(100),
  
  // State Pension
  fullNIContribution: Yup.boolean().required('Full NI Contribution information is required'),
  niContributionYears: Yup.number().when('fullNIContribution', {
    is: false,
    then: Yup.number().positive().integer().max(35).required('NI Contribution Years is required when not full contribution'),
  }),
  
  // Investments
  investmentCurrentValue: Yup.number().min(0).required('Investment Current Value is required'),
  investmentAnnualContributions: Yup.number().min(0).required('Investment Annual Contributions is required'),
  investmentAnnualGrowthRate: Yup.number().min(0).max(100),
  investmentAnnualFees: Yup.number().min(0).max(100),
  
  // Cash
  cashCurrentValue: Yup.number().min(0).required('Cash Current Value is required'),
  cashInterestRate: Yup.number().min(0).max(100),
});
