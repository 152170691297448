import { Field } from 'formik';
import { TextField } from '@mui/material';

const CurrencyField = ({ name, label, ...props }) => {
  const currencyInputProps = {
    inputProps: {
      min: 0,
      style: {
        // Remove spinner arrows
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
    },
    InputProps: {
      startAdornment: "£ ",
    },
  };

  return (
    <Field
      fullWidth
      name={name}
      component={TextField}
      label={label}
      type="number"
      {...currencyInputProps}
      {...props}
    />
  );
};

export default CurrencyField; 