import React, { useState } from 'react';
import { Grid, Typography, IconButton, Popover } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info ico

const PopoverField = ({ label, popoverContent, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
                {children}
            </Grid>
            <Grid item>
                <IconButton
                    size="small"
                    onClick={handlePopoverOpen}
                    sx={{ marginLeft: 1 }}
                >
                     <InfoIcon fontSize="small" />
                </IconButton>
            </Grid>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2, maxWidth: 300 }}>{popoverContent}</Typography>
            </Popover>
        </Grid>
    );
};

export default PopoverField; 