import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

const InvestmentsStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="investmentCurrentValue"
          component={TextField}
          label="Combined Current Value"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="investmentAnnualContributions"
          component={TextField}
          label="Annual Contributions"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="investmentAnnualGrowthRate"
          component={TextField}
          label="Annual Growth Rate (%)"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="investmentAnnualFees"
          component={TextField}
          label="Annual Fees (%)"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default InvestmentsStep;



export const validationSchema = Yup.object({

  // Investments
  investmentCurrentValue: Yup.number().min(0).required('Investment Current Value is required'),
  investmentAnnualContributions: Yup.number().min(0).required('Investment Annual Contributions is required'),
  investmentAnnualGrowthRate: Yup.number().min(0).max(100),
  investmentAnnualFees: Yup.number().min(0).max(100),
  
});
