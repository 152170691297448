import React from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';

const IntroStep = () => {
    return (
        <>
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" component="h1" fontWeight="bold" gutterBottom>
                    Let's get started on your personalized financial roadmap!
                </Typography>

                <Typography variant="body1" paragraph>
                    In just 15 minutes, this simple guided questionnaire will collect the essential information we need to create a financial plan tailored to you. Once we've gathered your details, our expert team will build a roadmap designed to meet your unique goals and needs.
                </Typography>

                <Typography variant="body1" fontWeight="bold" gutterBottom>
                    Who would you like to include in your financial roadmap?
                </Typography>

                <Field name="planType">
                    {({ field }) => (
                        <RadioGroup {...field}>
                            <FormControlLabel value="individual" control={<Radio />} label="Just myself" />
                            <FormControlLabel value="couple" control={<Radio />} label="Myself and my spouse/partner" />
                        </RadioGroup>
                    )}
                </Field>
            </Box>
        </>
    );
};

export default IntroStep;

export const validationSchema = Yup.object({
    planType: Yup.string().required('Please select who to include in your financial roadmap'),
});
