import React from "react";
import { Container, Card, CardContent, Typography, Box, Grid, Toolbar, AppBar  } from "@mui/material";
import { Wizard } from "./Wizard.js";


const App = () => {
 
    return (
        
        
        <Box sx={{ bgcolor: "#e6f2ff", flexGrow: 1, bgcolor: "#e6f2ff", minHeight: '100vh' }}>
        {/* New Navigation Bar */}

               
                        <AppBar  position="static" sx={{ backgroundColor: '#193A53', height: '120px'}}>
                        <Container maxWidth="xl" sx={{minHeight: '120px'}}>
        <Toolbar variant='dense' disableGutters sx={{minHeight: '120px'}}>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="http://www.pleniry.com"
            sx={{
                fontSize: '25px',
             fontFamily: 'poppins-semibold,poppins,sans-serif',
              fontWeight: 700,
              letterSpacing: '.02em',
              color: '#D9D9D9',
              textDecoration: 'none',
              justifyContent: 'center',
              pl: '60px'
            }}
          >
                Pleniry
          </Typography>
          </Toolbar>
          </Container>
            </AppBar>


            <Container maxWidth="xl">
                <Grid sx={{
                    display: "flex",
                    alignItems: "center", // This centers the content vertically
                    paddingBottom: 10,
                    paddingTop: 10,
                }} container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ bgcolor: "white", boxShadow: 3, borderRadius: 2 }}>
                            <CardContent sx={{ padding: 4 }}>
                                <Wizard/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default App;