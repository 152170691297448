import React from 'react';
import {Grid, MenuItem, Typography, FormControlLabel} from '@mui/material';
import {Field, useFormikContext} from 'formik';
import {TextField, Select, Switch} from 'formik-material-ui';
import * as Yup from 'yup';
import PopoverField from "../../components/PopoverField";
import CurrencyField from "../../components/CurrencyField";
import FormStepContainer from "../FormStepContaner";

const PersonalPensionStep = () => {
    const {values} = useFormikContext();
    const hasPensionsPrimary = values.hasPensionsPrimary;
    const fullNIContributionPrimary = values.fullNIContributionPrimary;
    const makesContributionsPrimary = values.makesContributionsPrimary;
    const hasPensionsSpouse = values.hasPensionsSpouse;

    return (

        <FormStepContainer
            primary={<>
                <PopoverField
                    label="hasPensionsPrimary"
                    popoverContent="Please toggle this switch if you have any pensions as the primary account holder."
                >
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                name="hasPensionsPrimary"
                                type="checkbox"
                            />
                        }
                        label="I have pensions"
                    />
                </PopoverField>

                {/* Only render this block if hasPrimaryPensions is true */}
                {hasPensionsPrimary && (
                    <>
                        <Grid item xs={12}>
                            <PopoverField
                                label="currentPensionValuePrimary"
                                popoverContent="Enter the current value of your pension."
                            >
                                <CurrencyField
                                    fullWidth
                                    name="currentPensionValuePrimary"
                                    component={TextField}
                                    label="Current Total pension value (all pensions)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="providerDetails"
                                popoverContent="Enter pension provider details."
                            >
                                <Field
                                    fullWidth
                                    name="currentPensionProviderDetailsPrimary"
                                    component={TextField}
                                    label="Pension Provider Details"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="employerContributionType"
                                popoverContent="Select the type of employer contribution."
                            >
                                <Field
                                    fullWidth
                                    name="annualContributions.employer.type"
                                    component={Select}
                                    label="Employer Contribution Type"
                                >
                                    <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                    <MenuItem value="fixed">Fixed Amount</MenuItem>
                                </Field>
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="employerContributionValue"
                                popoverContent="Enter the value of employer contribution."
                            >
                                <Field
                                    fullWidth
                                    name="annualContributions.employer.value"
                                    component={TextField}
                                    label="Employer Contribution Value"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="annualGrowthRate"
                                popoverContent="Enter the expected annual growth rate of your pension."
                            >
                                <Field
                                    fullWidth
                                    name="annualGrowthRate"
                                    component={TextField}
                                    label="Annual Growth Rate (%)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="annualFees"
                                popoverContent="Enter the annual fees associated with your pension."
                            >
                                <Field
                                    fullWidth
                                    name="annualFees"
                                    component={TextField}
                                    label="Annual Fees (%)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="makesContributionsPrimary"
                                        type="checkbox"
                                    />
                                }
                                label="Are you currently contributing to a pension?"
                            />
                        </Grid>

                        {makesContributionsPrimary && (
                            <>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionType"
                                        popoverContent="Select the type of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributions.employee.type"
                                            component={Select}
                                            label="Employee Contribution Type"
                                        >
                                            <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                            <MenuItem value="fixed">Fixed Amount</MenuItem>
                                        </Field>
                                    </PopoverField>
                                </Grid>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionValue"
                                        popoverContent="Enter the value of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributions.employee.value"
                                            component={TextField}
                                            label="Employee Contribution Value"
                                            type="number"
                                        />
                                    </PopoverField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="monthlyContributionsPrimary"
                                        component={TextField}
                                        label="Your Monthly Contribution"
                                        type="number"
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="fullNIContributionPrimary"
                                        type="checkbox"
                                    />
                                }
                                label="Will you have contributed National Insurance for 35 years at retirement?"
                            />
                        </Grid>
                        {fullNIContributionPrimary && (

                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    name="niContributionYearsPrimary"
                                    component={TextField}
                                    label="Total years of National Insurance contributions at retirement"
                                    type="number"
                                />
                            </Grid>)
                        }
                    </>
                )
                }</>}

            secondary={
                true ? "" : <>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="spousePensionValue"
                            component={TextField}
                            label="Current Total Pension Value (all pensions)"
                            type="number"
                        />
                    </Grid>
                </>
            }
        ></FormStepContainer>)
};

export default PersonalPensionStep;


export const validationSchema = Yup.object({

    // // Personal Pension
    // currentPensionValue: Yup.number().min(0).required('Current Pension Value is required'),
    // pensionType: Yup.string().oneOf(['Personal Pension', 'SIPP', 'Group Pension', 'DB']).required('Pension Type is required'),
    // annualContributions: Yup.object({
    //     employee: Yup.object({
    //         type: Yup.string().oneOf(['percentage', 'fixed']).required('Employee contribution type is required'),
    //         value: Yup.number().min(0).required('Employee contribution value is required'),
    //     }),
    //     employer: Yup.object({
    //         type: Yup.string().oneOf(['percentage', 'fixed']).required('Employer contribution type is required'),
    //         value: Yup.number().min(0).required('Employer contribution value is required'),
    //     }),
    // }),
    // annualGrowthRate: Yup.number().min(0).max(100),
    // annualFees: Yup.number().min(0).max(100),


});
