import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import CurrencyField from "../../components/CurrencyField";
import PopoverField from '../../components/PopoverField';

const ExpenditureStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PopoverField
          label="monthlyEssentialSpending"
          popoverContent={
            <div>
              <Typography variant="body2">
                Essential spending refers to the necessary expenses you need to cover every month to maintain your day-to-day living. These are fixed or recurring costs that are typically non-negotiable. Examples include:
              </Typography>
              <ul>
                <li>Rent or mortgage payments</li>
                <li>Utilities (electricity, water, gas)</li>
                <li>Groceries and household essentials</li>
                <li>Insurance (health, home, auto)</li>
                <li>Transportation (car payments, fuel, public transit)</li>
                <li>Loan repayments</li>
              </ul>
              <Typography variant="body2">
                These expenses are critical to meet your basic needs and financial obligations.
              </Typography>
            </div>
          }
        >
          <CurrencyField
            fullWidth
            name="monthlyEssentialSpending"
            component={TextField}
            label="Monthly Essential Spending"
            type="number"
          />
        </PopoverField>
      </Grid>
      <Grid item xs={12}>
        <PopoverField
          label="monthlyDiscretionarySpending"
          popoverContent={
            <div>
              <Typography variant="body2">
                Discretionary spending includes the non-essential expenses that vary month to month. These are more flexible and usually cover lifestyle choices or leisure activities. Examples include:
              </Typography>
              <ul>
                <li>Dining out or takeout</li>
                <li>Entertainment (movies, subscriptions, hobbies)</li>
                <li>Travel and vacations</li>
                <li>Shopping (clothing, electronics, etc.)</li>
                <li>Gym memberships or fitness activities</li>
              </ul>
              <Typography variant="body2">
                This spending is optional and can be adjusted based on your budget or savings goals.
              </Typography>
            </div>
          }
        >
          <CurrencyField
            fullWidth
            name="monthlyDiscretionarySpending"
            component={TextField}
            label="Monthly Discretionary Spending"
            type="number"
          />
        </PopoverField>
      </Grid>
    </Grid>
  );
};

export default ExpenditureStep;

export const validationSchema = Yup.object({
  // Expenditure
  monthlyEssentialSpending: Yup.number().positive().required('Monthly Essential Spending is required'),
  monthlyDiscretionarySpending: Yup.number().positive().required('Monthly Discretionary Spending is required'),
});
