import React, { useState } from "react";
import { Form } from "formik";
import { Button, Stack, Step, StepLabel, Stepper, Typography, Grid, Box, Avatar, Paper } from "@mui/material";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import { validationSchema } from "./validationSchema";
import { Nav } from './forms/nav'
import PersonalStep, { validationSchema as personalStepValidationSchema } from "./forms/steps/PersonalStep.js";
import ExpenditureStep, { validationSchema as expenditureStepValidationSchema } from "./forms/steps/ExpenditureStep.js";
import PersonalPensionStep, { validationSchema as pensionStepValidationSchema } from "./forms/steps/PersonalPensionStep.js";
import InvestmentsStep, { validationSchema as investmentsStepValidationSchema } from "./forms/steps/InvestmentsStep.js";
import CashStep, { validationSchema as cashStepValidationSchema } from "./forms/steps/CashStep.js";
import IntroStep, { validationSchema as introStepValidationSchema } from "./forms/steps/IntroStep.js"
import stringify from 'json-stringify-pretty-compact';
import { MobileStepper, StepContent } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import IncomeStep, {validationSchema as incomeStepValidationSchema} from "./forms/steps/IncomeStep.js";


const stepsArray = [
  <IntroStep key="intro" validationSchema={introStepValidationSchema} label="Intro" buttonText="Let's go!" />,
  <PersonalStep key="person1" validationSchema={personalStepValidationSchema} label="Personal Information" />,
  <IncomeStep key="person1" validationSchema={incomeStepValidationSchema} label="Income" />,
  <ExpenditureStep key="expenditure" validationSchema={expenditureStepValidationSchema} label="Household Expenditure" />,
  <PersonalPensionStep key="pension" validationSchema={pensionStepValidationSchema} label="Pension" />,
  <InvestmentsStep key="investments" validationSchema={investmentsStepValidationSchema} label="Investments" />,
  <CashStep key="cash" validationSchema={cashStepValidationSchema} label="Cash" buttonText="Finish" />
];


export const Wizard = () => {

  // const initialValues = {
  //   planType: "",
  //   // Personal
  //   namePrimary: "",
  //   nameSpouse: "",
  //   surnamePrimary: "",
  //   surnameSpouse: "",
  //   emailPrimary: "",
  //   emailSpouse: "",
  //   dobPrimary: "",
  //   dobSpouse: "",
  //   retirementAgePrimary: "",
  //   retirementAgeSpouse: "",
  
  //   //Income:
  //   occupationPrimary: "",
  //   annualSalaryPrimary: "",
  //   hasOtherIncomePrimary: false,
  //   otherGrossMontlyIncomePrimary: "",
  //   otherMonthlyIncomeDescriptionPrimary: "",
  
  //   occupationSpouse: "",
  //   annualSalarySpouse: "",
  //   hasOtherIncomeSpouse: false,
  //   otherGrossMontlyIncomeSpouse: "",
  //   otherMonthlyIncomeDescriptionSpouse: ""    ,
  //   otherMonthlyIncomeDescriptionPrimary: "",
  //   // Expenditure
  //   monthlyEssentialSpending: "",,
  //   monthlyDiscretionarySpending: "",
  //   // Personal Pension
  //   hasPrimaryPensions: true,
  //   currentPensionValue: "",
  //   pensionType: "",
  //   annualContributions: {
  //     employee: { type: "percentage", value: "" },
  //     employer: { type: "percentage", value: "" },
  //   },
  //   annualGrowthRate: 4,
  //   annualFees: 0.5,
  //   // State Pension
  //   fullNIContribution: false,
  //   niContributionYears: "",
  //   // Investments
  //   investmentCurrentValue: "",
  //   investmentAnnualContributions: "",
  //   investmentAnnualGrowthRate: 4,
  //   investmentAnnualFees: 1,
  //   // Cash
  //   cashCurrentValue: "",
  //   cashInterestRate: 2,
  // };
  const initialValues = {
    planType: "", // or "single"
    // Personal
    namePrimary: "John",
    nameSpouse: "Jane",
    surnamePrimary: "Smith",
    surnameSpouse: "Smith",
    emailPrimary: "john.smith@example.com",
    emailSpouse: "jane.smith@example.com",
    dobPrimary: "1980-05-15",
    dobSpouse: "1982-03-20",
    retirementAgePrimary: "65",
    retirementAgeSpouse: "65",
    
    // Income
    occupationPrimary: "Software Engineer",
    annualSalaryPrimary: "85000",
    hasOtherIncomePrimary: false,
    otherGrossMontlyIncomePrimary: "1000",
    otherMonthlyIncomeDescriptionPrimary: "Rental Income",
    
    occupationSpouse: "Marketing Manager",
    annualSalarySpouse: "65000",
    hasOtherIncomeSpouse: false,
    otherGrossMontlyIncomeSpouse: "",
    otherMonthlyIncomeDescriptionSpouse: "",
    
    // Expenditure
    monthlyEssentialSpending: "45000",
    monthlyDiscretionarySpending: "25000",
    
    // Pension
    hasPensionsPrimary: true,
    currentPensionValuePrimary: "250000",
    currentPensionProviderDetailsPrimary: "",
    annualContributions: {
      employee: { type: "percentage", value: "5" },
      employer: { type: "percentage", value: "3" },
    },
    annualGrowthRate: 4,
    annualFees: 0.5,
    makesContributionsPrimary: false,
    monthlyContributionsPrimary: 0,
    makesContributionsSpouse: false,
    monthlyContributionsSpouse: 0,

    // State Pension
    fullNIContributionPrimary: true,
    niContributionYearsPrimary: "25",
    
    // Investments
    investmentCurrentValue: "100000",
    investmentAnnualContributions: "12000",
    investmentAnnualGrowthRate: 4,
    investmentAnnualFees: 1,
    
    // Cash
    cashCurrentValue: "50000",
    cashInterestRate: 2,
  };

  const handleSubit = async (values) => {

    try {
      // Prepare the data for submission
      const formData = {
        title: `${values.name} ${values.surname} ${values.email}`,
        content: stringify(values, {
          indent: 2,
          maxLength: 80
        })
      };

      const response = await axios.post('https://app.pleniry.com/api/form-responses/post', formData);

      alert("Form submitted successfully")
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      alert("Error submitting form")
      console.error('Error submitting form:', error);

      // Set an error status

    } finally {

    }
  }



  const [step, setStep] = useState(0);
  const currentStep = stepsArray[step];

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const View = isMobile ? Mobile : Desktop;

  return (
    <Grid container spacing={3}>

      <View step={step}>
        <Formik
        
          initialValues={initialValues}
          validationSchema={currentStep.props.validationSchema}
          onSubmit={handleSubit}
        >
          <Form style={{marginTop: '2em', marginBottom: '2em'}}>
            {currentStep}
            <Nav step={currentStep} stepIndex={step} steps={stepsArray} onSubmit={handleSubit} setStep={setStep} />
          </Form>
        </Formik>

      </View>


    </Grid>
  );
};

const Desktop = ({ children, step }) => {
  return (
    <>

      <Grid item xs={12} md={4} lg={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Stepper activeStep={step} orientation="vertical">
          {stepsArray.map((child, index) => (
            <Step key={child.props.label} completed={step > index}>
              <StepLabel>{child.props.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Step {step + 1} / {stepsArray.length} <b>{stepsArray[step].props.label}</b>
        </Typography>

        <hr />
        <br />

        { children }
      </Grid></>
  );
};

function Mobile({ step, children }) {
  return (
    <Grid item xs={12}>
      <Box sx={{ margin: '0 auto' }}>
        <Stepper activeStep={step} orientation="vertical">
          {stepsArray.map((_, index) => (
            <Step key={stepsArray[index].props.label}>
              <StepLabel>
                {stepsArray[index].props.label}
              </StepLabel>
              <StepContent>
                {children}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Grid>
  );
}