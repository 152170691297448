import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

const CashStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="cashCurrentValue"
          component={TextField}
          label="Current Value"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="cashInterestRate"
          component={TextField}
          label="Interest Rate (%)"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default CashStep;



export const validationSchema = Yup.object({

  // Cash
  cashCurrentValue: Yup.number().min(0).required('Cash Current Value is required'),
  cashInterestRate: Yup.number().min(0).max(100),
});
